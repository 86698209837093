import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import useBaseUtils from "~/functions/useBaseUtils";
import useCiqTableOptions from "~/functions/useCiqTableOptions";
import useDateTimeFormatters from "~/functions/useDateTimeFormatters";
import useNumberFormatters from "~/functions/useNumberFormatters";
import { checkForInvalidEPS, createChartData, createCurrencyFormatter, createHistoricalTable, createNumberFormatter, createPercentFormatter, createStoreDataObject, createHistoricalTableFilteredData, createHistoricalYearsAcc, getWarningMessageByCompanyDescription, setDefaultRange, createForecastTable, getInitialDateStartingPoint, getInitEstimatesDataObj, getInitFinancialsDataObj } from "~/utils/valuationTransform/peModel";
import { updateValuationOutput } from "~/utils/valuationTransform/index";
import { initialFilterState, createInitialFilterState, historicalRowsTemplate } from "~/utils/valuationTransform/peValuationTemplates";
var useInitialPeValuationInputs = function useInitialPeValuationInputs(_ref) {
  var templates = _ref.templates;
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var _useBaseUtils = useBaseUtils(),
    dev = _useBaseUtils.dev,
    customGroup = _useBaseUtils.customGroup;
  var _useNumberFormatters = useNumberFormatters(),
    dynamicTwoDigitCurrencyFormatter = _useNumberFormatters.dynamicTwoDigitCurrencyFormatter;
  var _useCiqTableOptions = useCiqTableOptions({
      tableType: "estimates",
      storeModule: "ciq"
    }),
    currentIso = _useCiqTableOptions.currentIso;
  var filterState = createInitialFilterState(initialFilterState, templates.assumptionCases);
  var _useDateTimeFormatter = useDateTimeFormatters(),
    utcEpochToShortDate = _useDateTimeFormatter.utcEpochToShortDate,
    parseUtcDateTime = _useDateTimeFormatter.parseUtcDateTime;
  var filters = ref(_objectSpread({}, filterState));
  var resetCount = ref(0);
  var currentDataset = computed(function () {
    return $store.state.valuationModel.data.currentDataset;
  });
  var localInitialLoading = computed(function () {
    if ($store.state.valuationModel.currentModelId) {
      return $store.state.valuationModel.loading.initialLoad;
    }
    return loading.value;
  });
  var $newStoreDataObject = computed(function () {
    if (localInitialLoading.value) {
      return {};
    }
    if ($store.state.valuationModel.currentModelId) {
      return $store.state.valuationModel.data.storeDataObject;
    }
    return createStoreDataObject({
      $store: $store,
      isDev: dev.value,
      currentDataset: $store.state.valuationModel.data.currentDataset,
      parseUtcDateTime: parseUtcDateTime,
      utcEpochToShortDate: utcEpochToShortDate.value,
      estimatesDataArr: templates.estimatesDataArray,
      valuationDataArr: templates.valuationDataArray,
      computedMetricsArr: templates.calculatedMetrics,
      currentIso: currentIso.value,
      requiredMetricKeys: templates.requiredMetrics
    });
  });
  var actualDataset = computed(function () {
    return $store.state.valuationModel.data.currentDataset;
  });
  var loading = computed(function () {
    var loadingStore = $store.state.ciq.fetchingEstimates || $store.state.ciq.fetchingValuation || $store.state.ciq.fetchingFinancials;
    return loadingStore;
  });
  var formatNumber = computed(function () {
    return createNumberFormatter({
      minDigits: filters.value.decimals,
      maxDigits: filters.value.decimals,
      lang: navigator.language
    });
  });
  var oneDigitPercentFormatter = computed(function () {
    var lang = navigator.language || "default";
    return createPercentFormatter({
      minDigits: 1,
      maxDigits: 1,
      lang: lang
    });
  });
  var formatPercent = computed(function () {
    var lang = navigator.language || "default";
    return createPercentFormatter({
      minDigits: filters.value.decimals,
      maxDigits: filters.value.decimals,
      lang: lang
    });
  });
  var formatCurrency = computed(function () {
    return dynamicTwoDigitCurrencyFormatter(currentIso.value);
  });
  var historicalTable = computed(function () {
    var _$store$state$valuati;
    var currentDataset = $store.state.valuationModel.data.currentDataset;
    return createHistoricalTable({
      templateRows: historicalRowsTemplate,
      valuationOutput: valuationOutputDataObject.value,
      mostRecentActualDateKeyStr: $store.state.valuationModel.data.storeDataObject.mostRecentActualDateKeyStr,
      dateFormatter: utcEpochToShortDate.value,
      numberFormatter: formatNumber.value,
      percentFormatter: formatPercent.value,
      currencyFormatter: formatCurrency.value,
      fullfilledDates: (_$store$state$valuati = $store.state.valuationModel.data) === null || _$store$state$valuati === void 0 || (_$store$state$valuati = _$store$state$valuati.storeDataObject) === null || _$store$state$valuati === void 0 || (_$store$state$valuati = _$store$state$valuati.datasets) === null || _$store$state$valuati === void 0 || (_$store$state$valuati = _$store$state$valuati[currentDataset]) === null || _$store$state$valuati === void 0 ? void 0 : _$store$state$valuati.fullfilledDates,
      currentUserTier: customGroup.value
    });
  });
  var forecastTable = computed(function () {
    var _initialStartEndpoint, _initialStartEndpoint2;
    var yearStart = (_initialStartEndpoint = initialStartEndpoint.value) === null || _initialStartEndpoint === void 0 ? void 0 : _initialStartEndpoint.startYear;
    var yearEnd = (_initialStartEndpoint2 = initialStartEndpoint.value) === null || _initialStartEndpoint2 === void 0 ? void 0 : _initialStartEndpoint2.endYear;
    return createForecastTable(templates.assumptionCases, assumptionsOutput.value, function () {
      return true;
    }, $store.state.valuationModel.data.storeDataObject, yearStart, yearEnd, {
      utcEpochToShortDate: utcEpochToShortDate.value,
      formatNumber: formatNumber.value,
      formatPercent: formatPercent.value,
      formatCurrency: formatCurrency.value
    });
  });
  var hasInvalidEPS = computed(function () {
    return checkForInvalidEPS({
      valuationOutputDataObject: valuationOutputDataObject.value,
      $storeDataObject: $newStoreDataObject.value,
      currentDataset: currentDataset.value
    });
  });
  var companyWarningMessage = computed(function () {
    var ticker = $store.state.ciq.ticker;
    return getWarningMessageByCompanyDescription(ticker.simpleindustrydescription, ticker.tickersymbol, hasInvalidEPS.value);
  });
  var initVendorDataObj = computed(function () {
    var _$newStoreDataObject$;
    return (_$newStoreDataObject$ = $newStoreDataObject.value) === null || _$newStoreDataObject$ === void 0 || (_$newStoreDataObject$ = _$newStoreDataObject$.datasets) === null || _$newStoreDataObject$ === void 0 || (_$newStoreDataObject$ = _$newStoreDataObject$[actualDataset.value]) === null || _$newStoreDataObject$ === void 0 ? void 0 : _$newStoreDataObject$.initVendorDataObj;
  });
  var freshInitVendorDataObj = computed(function () {
    var _$valuationStore$data, _$ciqStore$financials;
    var currentDataset = actualDataset.value;
    var $ciqStore = $store.state.ciq;
    var $valuationStore = $store.state.valuationModel;
    var mostRecentActualDateKeyStr = $valuationStore === null || $valuationStore === void 0 || (_$valuationStore$data = $valuationStore.data) === null || _$valuationStore$data === void 0 || (_$valuationStore$data = _$valuationStore$data.storeDataObject) === null || _$valuationStore$data === void 0 ? void 0 : _$valuationStore$data.mostRecentActualDateKeyStr;
    var amountOfYearsForward = $valuationStore.data.amountOfYearsForward;
    var estimatesDataArray = $valuationStore.templates.estimatesDataArray;
    if (currentDataset === "estimates") {
      return getInitEstimatesDataObj($ciqStore.estimates, estimatesDataArray, dev.value, amountOfYearsForward);
    }
    return getInitFinancialsDataObj(($ciqStore === null || $ciqStore === void 0 || (_$ciqStore$financials = $ciqStore.financials) === null || _$ciqStore$financials === void 0 || (_$ciqStore$financials = _$ciqStore$financials.a) === null || _$ciqStore$financials === void 0 ? void 0 : _$ciqStore$financials.resData) || {}, mostRecentActualDateKeyStr, amountOfYearsForward, estimatesDataArray);
  });
  var userInputDataObjs = computed(function () {
    return $store.state.valuationModel.data.userInputsDataArray;
  });
  var assumptionsOutput = computed(function () {
    if (localInitialLoading.value) {
      return [{}];
    }
    var cases = templates.assumptionCases.length > 0 ? templates.assumptionCases : [{}];
    return cases.map(function (_, idx) {
      var _$newStoreDataObject$2;
      return updateValuationOutput(initVendorDataObj.value, userInputDataObjs.value[idx], templates.estimatesDataArray, (_$newStoreDataObject$2 = $newStoreDataObject.value) === null || _$newStoreDataObject$2 === void 0 || (_$newStoreDataObject$2 = _$newStoreDataObject$2.datasets) === null || _$newStoreDataObject$2 === void 0 || (_$newStoreDataObject$2 = _$newStoreDataObject$2[actualDataset.value]) === null || _$newStoreDataObject$2 === void 0 ? void 0 : _$newStoreDataObject$2.mostRecentActualDateKeyStr, templates.calculatedMetrics, templates.valuationDataArray, $newStoreDataObject.value.fullfilledDates);
    });
  });
  var valuationOutputDataObject = computed(function () {
    return assumptionsOutput.value[filters.value.assumptionCase];
  });
  var forecastedSummaryDataArr = computed(function () {
    // get x items from array starting from the end, except for the last one
    var initialRangeIdx = filters.value.dateRange[0] - 1;
    var finalRangeIdx = filters.value.dateRange[1] - 1;
    var filtered = allDates.value.filter(function (_, idx) {
      var isInRange = idx >= initialRangeIdx && idx <= finalRangeIdx;
      return isInRange;
    });
    if (filters.value.reverse) {
      return filtered.reverse();
    }
    return filtered;
  });
  var currencies = computed(function () {
    return $newStoreDataObject.value.datasets[actualDataset.value].currencies;
  });
  var formatCurrencyNoDecimals = computed(function () {
    var _currencies$value$fil;
    var lang = navigator.language || "default";
    return createCurrencyFormatter({
      minDigits: 0,
      maxDigits: 0,
      lang: lang,
      isocode: (_currencies$value$fil = currencies.value[filters.value.currency]) === null || _currencies$value$fil === void 0 ? void 0 : _currencies$value$fil.code
    });
  });
  var formatCurrencyReactive = computed(function () {
    var _currencies$value$fil2;
    var lang = navigator.language || "default";
    return createCurrencyFormatter({
      minDigits: filters.value.decimals,
      maxDigits: filters.value.decimals,
      lang: lang,
      isocode: (_currencies$value$fil2 = currencies.value[filters.value.currency]) === null || _currencies$value$fil2 === void 0 ? void 0 : _currencies$value$fil2.code
    });
  });
  var formatCurrencyTwoDecimals = computed(function () {
    var _currencies$value$fil3;
    var lang = navigator.language || "default";
    return createCurrencyFormatter({
      minDigits: 2,
      maxDigits: 2,
      lang: lang,
      isocode: (_currencies$value$fil3 = currencies.value[filters.value.currency]) === null || _currencies$value$fil3 === void 0 ? void 0 : _currencies$value$fil3.code
    });
  });
  var formatFloatTwoDigits = computed(function () {
    var lang = navigator.language || "default";
    return new Intl.NumberFormat(lang, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  });
  var createChartOffset = function createChartOffset(amountOfYearsForward) {
    // initial offset array, two years back
    var offset = [-1, 0]; // loop through the amount of years forward and add to the offset array
    for (var i = 1; i <= amountOfYearsForward; i++) {
      offset.push(i);
    }
    return offset;
  };
  var chartData = computed(function () {
    var _$newStoreDataObject$3; // one year backwards, three years forward
    var currentYear = $store.state.valuationModel.data.currentYear;
    var endYear = initialStartEndpoint.value.endYear;
    var chartYearOffset = createChartOffset(endYear - currentYear);
    return createChartData({
      offset: chartYearOffset,
      fullfilledDates: $newStoreDataObject === null || $newStoreDataObject === void 0 || (_$newStoreDataObject$3 = $newStoreDataObject.value) === null || _$newStoreDataObject$3 === void 0 ? void 0 : _$newStoreDataObject$3.fullfilledDates,
      mostRecentActualDateKeyStr: $newStoreDataObject.value.mostRecentActualDateKeyStr,
      assumptionCases: templates.assumptionCases,
      assumptionsOutput: assumptionsOutput.value,
      formatters: {
        formatCurrencyNoDecimals: formatCurrencyNoDecimals.value,
        formatCurrency: formatCurrencyTwoDecimals.value,
        "float": formatFloatTwoDigits.value,
        dateFormatter: utcEpochToShortDate.value
      },
      amountOfYearsForward: $store.state.valuationModel.data.amountOfYearsForward
    });
  });
  var initialStartEndpoint = computed(function () {
    var _$store$state$valuati2;
    var currentDataset = $store.state.valuationModel.data.currentDataset;
    return getInitialDateStartingPoint({
      dates: (_$store$state$valuati2 = $store.state.valuationModel.data.storeDataObject.datasets) === null || _$store$state$valuati2 === void 0 || (_$store$state$valuati2 = _$store$state$valuati2[currentDataset]) === null || _$store$state$valuati2 === void 0 ? void 0 : _$store$state$valuati2.fullfilledDates,
      dateRange: filters.value.dateRange
    });
  });
  var currentModelId = computed(function () {
    return $store.state.valuationModel.currentModelId;
  });
  watch([loading, currentModelId], function (newState) {
    var _newState = _slicedToArray(newState, 2),
      loadingState = _newState[0],
      modelId = _newState[1];
    var isNewModel = !modelId;
    if (isNewModel && loadingState === false || modelId) {
      var _$newStoreDataObject$4;
      if (isNewModel) {
        $store.dispatch("valuationModel/resetUserInputs");
      }
      setDefaultRange({
        datesArr: (_$newStoreDataObject$4 = $newStoreDataObject.value) === null || _$newStoreDataObject$4 === void 0 || (_$newStoreDataObject$4 = _$newStoreDataObject$4.datasets) === null || _$newStoreDataObject$4 === void 0 || (_$newStoreDataObject$4 = _$newStoreDataObject$4[actualDataset.value]) === null || _$newStoreDataObject$4 === void 0 ? void 0 : _$newStoreDataObject$4.fullfilledDates,
        refToUpdate: filters
      });
    }
  }, {
    immediate: true
  });
  var currentDatasetObj = computed(function () {
    var _$newStoreDataObject$5;
    return (_$newStoreDataObject$5 = $newStoreDataObject.value) === null || _$newStoreDataObject$5 === void 0 || (_$newStoreDataObject$5 = _$newStoreDataObject$5.datasets) === null || _$newStoreDataObject$5 === void 0 ? void 0 : _$newStoreDataObject$5[actualDataset.value];
  });
  var allDates = computed(function () {
    var _currentDatasetObj$va;
    return (_currentDatasetObj$va = currentDatasetObj.value) === null || _currentDatasetObj$va === void 0 ? void 0 : _currentDatasetObj$va.fullfilledDates;
  });
  var isValidLastActuals = computed(function () {
    var _currentDatasetObj$va2;
    return (_currentDatasetObj$va2 = currentDatasetObj.value) === null || _currentDatasetObj$va2 === void 0 ? void 0 : _currentDatasetObj$va2.isValidLastActuals;
  });
  var recommendedDataset = computed(function () {
    var _$newStoreDataObject$6;
    return (_$newStoreDataObject$6 = $newStoreDataObject.value) === null || _$newStoreDataObject$6 === void 0 ? void 0 : _$newStoreDataObject$6.recommendedDataset;
  });
  var isFinancialDataSet = computed(function () {
    return actualDataset.value === "financials";
  });
  var rowLabels = computed(function () {
    var _currentDatasetObj$va3;
    return (_currentDatasetObj$va3 = currentDatasetObj.value) === null || _currentDatasetObj$va3 === void 0 ? void 0 : _currentDatasetObj$va3.labels;
  });
  var mostRecentActualDateKeyStr = computed(function () {
    var _currentDatasetObj$va4;
    return (_currentDatasetObj$va4 = currentDatasetObj.value) === null || _currentDatasetObj$va4 === void 0 ? void 0 : _currentDatasetObj$va4.mostRecentActualDateKeyStr;
  });
  var stockValues = computed(function () {
    return $newStoreDataObject.value.stockValues;
  });
  var historicalYearObjConfig = [{
    label: "1 Year"
  }, {
    label: "3 Year",
    useAsFallback: true
  }, {
    label: "5 Year",
    hideIfNotAvailable: true
  }, {
    label: "10 Year",
    hideIfNotAvailable: true
  }];
  var historicalYears = computed(function () {
    return createHistoricalYearsAcc({
      historicalYearObjConfig: historicalYearObjConfig,
      historicalTableData: historicalTable.value
    });
  });
  var historicalTableFilteredData = computed(function () {
    return createHistoricalTableFilteredData({
      historicalYears: historicalYears.value,
      historicalTableData: historicalTable.value
    });
  });
  var getMostRecentKey = function getMostRecentKey(dataset, $store) {
    var _$store$state;
    return $store === null || $store === void 0 || (_$store$state = $store.state) === null || _$store$state === void 0 || (_$store$state = _$store$state.ciq) === null || _$store$state === void 0 || (_$store$state = _$store$state[dataset]) === null || _$store$state === void 0 || (_$store$state = _$store$state.a) === null || _$store$state === void 0 ? void 0 : _$store$state.mostRecentDateKey;
  };
  var isOutOfDate = computed(function () {
    var newKey = getMostRecentKey(actualDataset.value, $store);
    var modelKey = mostRecentActualDateKeyStr.value;
    if (!newKey || !modelKey) {
      return false;
    }
    return newKey !== modelKey;
  });
  return {
    allDates: allDates,
    loading: loading,
    initVendorDataObj: initVendorDataObj,
    isFinancialDataSet: isFinancialDataSet,
    isOutOfDate: isOutOfDate,
    freshInitVendorDataObj: freshInitVendorDataObj,
    isModelDisabled: $newStoreDataObject.value.isModelDisabled,
    mostRecentActualDateKeyStr: mostRecentActualDateKeyStr,
    userInputDataObjs: userInputDataObjs,
    currencies: currencies,
    valuationOutputDataObject: valuationOutputDataObject,
    hasInvalidEPS: hasInvalidEPS,
    companyWarningMessage: companyWarningMessage,
    stockValues: stockValues,
    filters: filters,
    resetCount: resetCount,
    setDefaultRange: setDefaultRange,
    forecastTable: forecastTable,
    utcEpochToShortDate: utcEpochToShortDate,
    chartData: chartData,
    recommendedDataset: recommendedDataset,
    currentDataset: currentDataset,
    isValidLastActuals: isValidLastActuals,
    actualDataset: actualDataset,
    oneDigitPercentFormatter: oneDigitPercentFormatter,
    formatPercent: formatPercent,
    formatNumber: formatNumber,
    formatCurrencyTwoDecimals: formatCurrencyTwoDecimals,
    formatCurrencyReactive: formatCurrencyReactive,
    rowLabels: rowLabels,
    forecastedSummaryDataArr: forecastedSummaryDataArr,
    historicalYears: historicalYears,
    historicalTableFilteredData: historicalTableFilteredData
  };
};
export default useInitialPeValuationInputs;